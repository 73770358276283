<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              v-for="breadcrumb in breadcrumbs"
              :to="breadcrumb.to"
              :key="breadcrumb.name"
            >{{ breadcrumb.name }}</b-breadcrumb-item>

            <b-breadcrumb-item active>查看角色</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-overlay :show="showLoading">
          <b-card>
            <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
              <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
                {{ organizationName }}查看角色
              </h4>
              <role-form :role="role" :is-read-only="true" ref="form"></role-form>
            </div>

            <div class="row">
              <div class="col-12 col-xl-8 mt-3">
                <div class="row" v-for="(permissions, key) in groupedPermissions" :key="key">
                  <p class="col-lg-2 col-12 px-0">{{ key }}</p>
                  <div class="col-lg-10 col-12 d-flex flex-wrap mb-3">
                    <b-form-checkbox
                      v-for="permission in permissions"
                      :key="permission.key"
                      :value="permission.id"
                      v-model="checkedPermissions"
                      disabled
                    >
                      {{ permission.name }}
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-center">
              <b-button
                class="mr-3"
                :to="getRoleListLink()"
                variant="outline-danger"
              >返回
              </b-button>
            </div>
          </b-card>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import https from "@/apis/https"
import roleApi from "@/apis/role";
import RoleForm from "./RoleForm"
import orgNameMixin from "@/mixins/organizationName"

export default {
  mixins: [orgNameMixin],
  components: { RoleForm },
  data() {
    return {
      showLoading: false,
      role: {
        name: null,
        code: null,
        description: null,
      },
      organizationName: this.$store.state.general.organization.name,
      groupedPermissions: {},
      checkedPermissions: [],
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapState("auth", {
      user: (state) => state.user,
    }),
    orgId() {
      return this.$route.params.orgId || this.organization.id;
    },
    breadcrumbs() {
      if (this.$route.name == 'OrgRoleShow') {
        return [
          { to: { name: 'SystemSettingsHome' }, name: '全系統管理' },
          { to: { name: 'OrganizationList' }, name: '組織管理' },
          { to: { name: 'RoleList', params: { orgId: this.orgId } }, name: '角色列表' },
        ]
      }

      return [
        { to: { name: 'RoleList' }, name: '角色列表' },
      ]
    }
  },
  async mounted() {
    await this.fetchRole();
    await this.fetchPermissions();
  },
  methods: {
    async fetchRole() {
      const result = await roleApi.getRole(this.orgId, this.$route.params.role_id);
      this.role = result.data.data;
      this.checkedPermissions = this.role.permissions.map(permission => permission.id)
    },
    async fetchPermissions() {
      const result = await https.get(`admin/permissions`)
      const adminPermissions = ['ORGANIZATION_LIST_VIEW', 'ORGANIZATION_MODIFY', 'ORGANIZATION_DELETE', 'MERCHANT_LIST_VIEW', 'MERCHANT_MODIFY', 'MERCHANT_DELETE'];
      const permissions = result.data.data.filter(permission => {
        if (adminPermissions.includes(permission.key) && !this.user.is_sys_admin) {
          // 編輯角色全縣，只有系統管理員可以
          return false;
        }
        return true;
      });
      this.groupedPermissions = permissions.reduce((group, permission) => {
        const { category } = permission;
        group[category] = group[category] ?? [];
        group[category].push(permission);
        return group;
      }, {});
    },
    getRoleListLink() {
      if (this.$route.name == 'OrgRoleShow') {
        return { name: 'OrgRoleList', params: { orgId: this.orgId }, }
      }
      return { name: 'RoleList' }
    }
  }
};
</script>
